import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  userName = null;
  password = null;

  constructor(
    private modalCtrl: ModalController,
    private generalService: GeneralService
  ) {}

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss();
  }

  async submitForm(loginForm) {
    const result = await this.generalService.login(
      this.userName,
      this.password
    );
    if (result) {
      this.modalCtrl.dismiss();
    }
  }
}
