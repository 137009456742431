import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

import Swal from 'sweetalert2';

import {
  LoadingController,
  ModalController,
  NavController,
} from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { LoginComponent } from '../components/login/login.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  public freeLance = null;
  public token = null;
  public envitomentData = null;

  constructor(
    private storage: Storage,
    private modalCtr: ModalController,
    private loadingCtrl: LoadingController,
    private http: HttpClient,
    private navCtrl: NavController,
    private router: Router
  ) {}

  loadStorage = async () => {
    await this.storage.create();
    if (!this.token) {
      this.token = await this.storageGet('token');
    }
    if (!this.freeLance) {
      this.freeLance = await this.storageGet('freeLance');
    }
  };

  storageGet = async (key: string) => {
    const value = await this.storage.get(key);
    return value;
  };

  storageSet = async (key: string, value: any) => {
    await this.storage.set(key, value);
  };

  storageDelete = async (key: string) => {
    await this.storage.remove(key);
  };

  storageClear = async () => {
    this.storage.clear();
  };

  isLoggedIn = async () => {
    await this.loadStorage();

    if (this.token) {
      return this.freeLance;
    }

    const modal = await this.modalCtr.create({
      component: LoginComponent,
      showBackdrop: false,
      backdropDismiss: false,
      swipeToClose: false,
      keyboardClose: false,
    });
    await modal.present();
    await modal.onDidDismiss();
    return this.freeLance;
  };

  getEnviroment() {
    return {
      domain: `${environment.domain}`,
      v8: `${environment.v8}`,
    };
  }

  async login(userName = '', password = '') {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...',
    });
    await loading.present();
    try {
      const formData = new FormData();
      formData.append('userName', userName);
      formData.append('password', password);
      const result: any = await this.http
        .post(`${environment.v8}/login`, formData)
        .toPromise();
      this.token = result.token;
      this.freeLance = result.freelance;
      await this.storageSet('token', this.token);
      await this.storageSet('freeLance', this.freeLance);
      loading.dismiss();
      return true;
    } catch (error) {
      if (error.status == 401) {
        Swal.fire({
          icon: 'error',
          heightAuto: false,
          title: 'Invalid username/password',
          text: error.error,
        });
      } else {
        this.handleHttpError(error);
      }
      loading.dismiss();
      return false;
    }
  }

  async logout() {
    try {
      const token = this.token;
      this.token = null;
      this.freeLance = null;
      this.storageClear();
      this.navCtrl.navigateRoot('logout');
    } catch (error) {
      console.error(error);
    }
  }

  async contactSave(newData) {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...',
    });
    await loading.present();
    try {
      const formData = new FormData();
      formData.append('freelance_phone', newData.freelance_phone);
      formData.append('freelance_fax', newData.freelance_fax);
      formData.append('freelance_address', newData.freelance_address);
      formData.append('freelance_city', newData.freelance_city);
      formData.append('freelance_state', newData.freelance_state);
      formData.append('freelance_postalcode', newData.freelance_postalcode);
      formData.append('freelance_contact', newData.freelance_contact);
      formData.append(
        'freelance_contact_phone',
        newData.freelance_contact_phone
      );
      const result: any = await this.http
        .post(`${environment.v8}/contact`, formData, {
          headers: new HttpHeaders({
            Authorization: `Bearer ${this.token}`,
          }),
        })
        .toPromise();
      loading.dismiss();
      Swal.fire({
        heightAuto: false,
        position: 'top-end',
        icon: 'success',
        title: 'Saved',
        showConfirmButton: false,
        timer: 1500,
      });
      this.freeLance = result;
      await this.storageSet('freeLance', this.freeLance);
      return result;
    } catch (error) {
      this.handleHttpError(error);
      loading.dismiss();
      return false;
    }
  }

  async passwordChange(newPassword = '') {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...',
    });
    await loading.present();
    try {
      const formData = new FormData();
      formData.append('newPassword', newPassword);
      const result: any = await this.http
        .post(`${environment.v8}/changepassword`, formData, {
          headers: new HttpHeaders({
            Authorization: `Bearer ${this.token}`,
          }),
        })
        .toPromise();
      loading.dismiss();
      Swal.fire({
        heightAuto: false,
        position: 'top-end',
        icon: 'success',
        title: 'Password changed',
        showConfirmButton: false,
        timer: 1500,
      });

      return true;
    } catch (error) {
      this.handleHttpError(error);
      loading.dismiss();
      return false;
    }
  }

  handleHttpError(error) {
    switch (error.status) {
      case 500:
        Swal.fire({
          icon: 'error',
          heightAuto: false,
          title: 'Server error',
          text: error.error,
        });
        break;
      case 401:
        this.logout();
        break;
      case 403:
        Swal.fire({
          icon: 'error',
          heightAuto: false,
          title: 'Resource is forbidden',
          text: error.error,
        });
        break;
      case 404:
        this.navCtrl.navigateForward('/notfound');
        break;
    }
  }

  getPreviousPath() {
    if (this.router.getCurrentNavigation().previousNavigation) {
      return this.router
        .getCurrentNavigation()
        .previousNavigation.finalUrl.toString();
    } else {
      return null;
    }
  }
}
